export class Config {
  static _instance: IConfig;
  public static get instance() {
    if (!Config._instance) {
      Config._instance = Config.init();
    }
    return Config._instance;
  }

  private static init(): IConfig {
    return process.env.config as any;
  }
}

type IConfig = {
  appKey: string;
  campaignId: number;
};
