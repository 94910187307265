import { Button } from '@hypercloud-kr/styling-kit';
import styled from '@emotion/styled';

type CouponSaveButtonProps = {
  onClick?: () => void;
};

function CouponSaveButton(props: CouponSaveButtonProps) {
  return (
    <CouponWrapper>
      <Button size={'XL'} color={'var(--blue-60)'} onClick={props.onClick}>
        쿠폰 저장
      </Button>
    </CouponWrapper>
  );
}

export default CouponSaveButton;

const CouponWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 20px;
`;
