import styled from '@emotion/styled';

type CloseBarProps = {
  onClick: () => void;
};

export function CloseBar({ onClick }: CloseBarProps) {
  return (
    <Container>
      <Button onClick={onClick}>
        <XIcon />
      </Button>
    </Container>
  );
}

const Container = styled.div`
  padding: 14px 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: sticky;
  top: 0;
  background: var(--cool-neutral-5);
`;

const Button = styled.div`
  background: inherit;
  border: none;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
  overflow: visible;
  cursor: pointer;
`;

const XIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M23.7476 8.25244L8.25238 23.7477"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M23.7476 23.7477L8.25238 8.25244"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);
