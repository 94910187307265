type IcInfoProps = {
  color?: string;
};
export const IcInfo = (props: IcInfoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 21C17.4706 21 21.5 16.9706 21.5 12C21.5 7.02944 17.4706 3 12.5 3C7.52944 3 3.5 7.02944 3.5 12C3.5 16.9706 7.52944 21 12.5 21ZM11.6 8.4C11.6 7.90294 12.0029 7.5 12.5 7.5C12.9971 7.5 13.4 7.90294 13.4 8.4C13.4 8.89706 12.9971 9.3 12.5 9.3C12.0029 9.3 11.6 8.89706 11.6 8.4ZM11.6 12C11.6 11.5029 12.0029 11.1 12.5 11.1C12.9971 11.1 13.4 11.5029 13.4 12V15.6C13.4 16.0971 12.9971 16.5 12.5 16.5C12.0029 16.5 11.6 16.0971 11.6 15.6V12Z"
      fill={props.color ?? 'currentColor'}
    />
  </svg>
);
