import styled from '@emotion/styled';
import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import CouponSaveButton from './CoupnSaveButton';
import { brandInfoList } from '../../constants/brand';
import { useCaptureHtml } from '../../hooks';
import { getEnvInfo } from '../../utils/getEnvInfo';

type CouponDetailProps = {
  id: number;
  brandId: number;
  onClickCouponSave: () => void;
};

function CouponDetail(props: CouponDetailProps) {
  const { htmlRef, capture } = useCaptureHtml();

  const brand = brandInfoList.find((brand) => brand.id === props.brandId);

  const couponQrUrl = useMemo(() => {
    const storeId = brand?.storeId;
    return `${getEnvInfo().storeUrl.replace(':storeId', `${storeId}`)}/${props.id}`;
  }, [props, brand]);

  const handleClickCouponSave = async () => {
    const isSuccess = await capture();
    if (isSuccess) {
      props.onClickCouponSave();
    }
  };

  return (
    <>
      <Wrapper>
        <CouponCardWrapper ref={htmlRef}>
          <CouponCard>
            <CouponTop>
              <ExpireDate>2024.08.18까지</ExpireDate>
              <QrWrapper>
                <QRCodeSVG value={couponQrUrl} />
              </QrWrapper>
              <CouponName>사은품 증정 쿠폰</CouponName>
            </CouponTop>
            <DashedHr />
            <CouponBottom>
              <DescriptionItem name={'사용처'}>
                <div>
                  <IconItem>
                    <ImageIcon src={brand.imageUrl} alt={brand.name} />
                    {brand.name}
                  </IconItem>
                </div>
              </DescriptionItem>
              <DescriptionItem name={'사은품'}>
                <IconItem>
                  <PresentIcon />
                  {brand.giftList.join(', ')}
                </IconItem>
              </DescriptionItem>
              <DescriptionItem name={'사용조건'}>
                {brand.condition !== ''
                  ? `${brand.condition} 이상 구매시`
                  : '모든 구매 고객'}
              </DescriptionItem>
            </CouponBottom>
          </CouponCard>
        </CouponCardWrapper>
      </Wrapper>
      <CouponSaveButton onClick={handleClickCouponSave} />
    </>
  );
}

export default CouponDetail;

const Wrapper = styled.section`
  padding: 24px 32px 48px;
`;

const CouponCardWrapper = styled.div`
  background-color: var(--cool-neutral-5,);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CouponCard = styled.div`
  padding: 24px 24px 40px;
  background: #dbdeee;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 326px;
`;

const CouponTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;

const QrWrapper = styled.div`
  padding: 16px;
  border-radius: 16px;
  background: var(--common-100);
`;

const CouponBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const ExpireDate = styled.div`
  color: var(--common-100);
  padding: 3px 10px;
  border-radius: 1000px;
  font: var(--label-02-500);
  background: var(--cool-neutral-70);
`;

const CouponName = styled.div`
  font: var(--headline-01-700);
  color: var(--cool-neutral-5);
`;

type DescriptionItemProps = {
  name: string;
};

const DashedHr = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="2"
      viewBox="0 0 280 2"
      preserveAspectRatio="none"
      fill="none"
    >
      <path
        d="M1 1H279"
        stroke="#ADB2CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="4 6"
      />
    </svg>
  );
};

const DescriptionItem = (props: PropsWithChildren<DescriptionItemProps>) => {
  return (
    <DescriptionItemWrapper>
      <DescriptionName>{props.name}</DescriptionName>
      <DescriptionContent>{props.children}</DescriptionContent>
    </DescriptionItemWrapper>
  );
};

const DescriptionItemWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  align-items: center;
`;

const DescriptionName = styled.div`
  font: var(--body-01-500);
  color: var(--cool-neutral-50);
`;

const DescriptionContent = styled.div`
  font: var(--body-01-500);
  color: var(--cool-neutral-10);
`;

const IconItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ImageIcon = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;

const PresentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2987 7.28853C11.2693 7.28853 10.3504 8.18549 10.3504 9.3998C10.3504 10.6141 11.2693 11.5111 12.2987 11.5111C13.328 11.5111 14.2469 10.6141 14.2469 9.3998C14.2469 8.18549 13.328 7.28853 12.2987 7.28853ZM7.89819 9.3998C7.89819 6.90758 9.82173 4.7998 12.2987 4.7998C13.8723 4.7998 15.2227 5.65062 15.9982 6.9103C16.7737 5.65062 18.124 4.7998 19.6977 4.7998C22.1747 4.7998 24.0982 6.90758 24.0982 9.3998C24.0982 11.892 22.1747 13.9998 19.6977 13.9998C18.124 13.9998 16.7737 13.149 15.9982 11.8893C15.2227 13.149 13.8723 13.9998 12.2987 13.9998C9.82173 13.9998 7.89819 11.892 7.89819 9.3998ZM19.6977 7.28853C18.6684 7.28853 17.7495 8.18549 17.7495 9.3998C17.7495 10.6141 18.6684 11.5111 19.6977 11.5111C20.7271 11.5111 21.646 10.6141 21.646 9.3998C21.646 8.18549 20.7271 7.28853 19.6977 7.28853Z"
      fill="#FFCD00"
    />
    <rect
      x="6.6001"
      y="13.2002"
      width="18.8"
      height="13.6"
      rx="2.62711"
      fill="#2F78DC"
    />
    <rect
      x="4.80005"
      y="9.2002"
      width="22.4"
      height="7.2"
      rx="2.10169"
      fill="#4592FB"
    />
    <path d="M16 16V26.8" stroke="#ECBF07" strokeWidth="2.4" />
    <path d="M16 9.2002V16.4002" stroke="#FFCD00" strokeWidth="3.516" />
  </svg>
);
