import styled from '@emotion/styled';
import { IcInfo } from './IcInfo';

function CouponDescription() {
  return (
    <Wrapper>
      <Title>
        <IcInfo />
        <span>유의사항</span>
      </Title>
      <Contents>
        <li>
          본 쿠폰은 현대백화점 목동점에서 사용 명시된 기간 내 1인 1회에 한해
          사용 가능합니다.{' '}
        </li>
        <li>결제 시 해당 쿠폰을 직원에게 제시하여 주시기 바랍니다.</li>
        <li>
          브랜드별 사은품 소진 시 사전 공지 없이 변경 또는 조기 종료될 수
          있습니다.
        </li>
        <li>
          구매 후 7일 이내 취소 시 사은품까지 모두 반납하셔야 환불 처리가
          가능합니다.
        </li>
      </Contents>
    </Wrapper>
  );
}

export default CouponDescription;

const Wrapper = styled.div`
  background: var(--cool-neutral-10);
  color: var(--cool-neutral-40);
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled.div`
  font: var(--headline-02-700);
  display: flex;
  gap: 4px;
`;

const Contents = styled.ul`
  font: var(--label-01-400);
  display: flex;
  flex-direction: column;
  gap: 3px;
  & > li {
    list-style: none;
    display: flex;
    gap: 2px;
    &::before {
      content: '-';
    }
  }
`;
