// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  --animate-duration: 0.3s;
  background: var(--cool-neutral-5, #1D1E21);
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

div:where(.swal2-container) .swal2-html-container {
  padding: 0 !important;
}

html.not-scroll {
  height: 100dvh !important;
}
#snappy-modal-area {
  height: 100dvh !important;
}`, "",{"version":3,"sources":["webpack://./src/globals.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,wBAAwB;EACxB,0CAA0C;AAC5C;AACA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  --animate-duration: 0.3s;\n  background: var(--cool-neutral-5, #1D1E21);\n}\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\ndiv:where(.swal2-container) .swal2-html-container {\n  padding: 0 !important;\n}\n\nhtml.not-scroll {\n  height: 100dvh !important;\n}\n#snappy-modal-area {\n  height: 100dvh !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
