import styled from '@emotion/styled';
import { MobileFullScreen } from '@hypercloud-kr/styling-kit';

export const UiLayer = styled.div`
  position: fixed;
  ${MobileFullScreen};
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  grid-template-areas:
    'top'
    'mid'
    'bottom';
  grid-gap: 32px;
  padding: 24px 0 20px;
  z-index: 101;
  left: 0;
  top: 0;
`;
