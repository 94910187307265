import { UiLayer } from '../layout/UiLayer';
import styled from '@emotion/styled';
import { Button } from '@hypercloud-kr/styling-kit';
import Intro from '../components/IntroImage.png';

export default function IntroPage() {
  return (
    <Wrapper>
      <ButtonWrapper>
        <Button
          size={'XL'}
          color={'var(--blue-60)'}
          onClick={() => {
            window.location.href = '/ar';
          }}
        >
          시작하기
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(UiLayer)`
  background: url(${Intro});
  padding: 24px 20px;
  background-position: center;
  background-size: cover;
`;

const ButtonWrapper = styled.div`
  grid-area: bottom;
`;

const IntroImage = styled.img`
  grid-area: mid;
  width: 100%;
  height: auto;
  margin: auto;
  max-width: 500px;
`;
