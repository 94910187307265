export type Brand = {
  id: number;
  storeId: number;
  name: string;
  category?: string;
  imageUrl?: string;
  condition: string;
  couponCode?: string;
  giftList: string[];
};

import Logo1 from './logo/img_logo_01.png';
import Logo2 from './logo/img_logo_02.png';
import Logo3 from './logo/img_logo_03.png';
import Logo4 from './logo/img_logo_04.png';
import Logo5 from './logo/img_logo_05.png';
import Logo6 from './logo/img_logo_06.png';
import Logo7 from './logo/img_logo_07.png';
import Logo8 from './logo/img_logo_08.png';
import Logo9 from './logo/img_logo_09.png';
import Logo10 from './logo/img_logo_10.png';
import Logo11 from './logo/img_logo_11.png';
import Logo12 from './logo/img_logo_12.png';
import Logo13 from './logo/img_logo_13.png';
import Logo14 from './logo/img_logo_14.png';
import Logo15 from './logo/img_logo_15.png';
import Logo16 from './logo/img_logo_16.png';
import Logo17 from './logo/img_logo_17.png';

export const brandInfoList: Brand[] = [
  {
    id: 0,
    storeId: 3,
    name: '디스커버리',
    condition: '10만원',
    imageUrl: Logo7,
    giftList: ['양말'],
    couponCode: '5',
  },
  {
    id: 1,
    storeId: 4,
    name: '노스페이스',
    condition: '15만원',
    imageUrl: Logo2,
    giftList: ['열쇠고리'],
    couponCode: '6',
  },
  {
    id: 2,
    storeId: 5,
    name: 'K2',
    condition: '15만원',
    imageUrl: Logo17,
    giftList: ['20% 금액할인'],
    couponCode: '7',
  },
  {
    id: 3,
    storeId: 6,
    name: '블랙야크',
    condition: '15만원',
    imageUrl: Logo10,
    giftList: ['우산'],
    couponCode: '8',
  },
  {
    id: 6,
    storeId: 7,
    name: '네파',
    condition: '15만원',
    imageUrl: Logo5,
    giftList: ['캠핑매트'],
    couponCode: '9',
  },
  {
    id: 7,
    storeId: 8,
    name: '컬럼비아',
    condition: '10만원',
    imageUrl: Logo13,
    giftList: ['여행용 파우치'],
    couponCode: '10',
  },
  {
    id: 8,
    storeId: 9,
    name: '밀레',
    condition: '10만원',
    imageUrl: Logo9,
    giftList: ['양말'],
    couponCode: '11',
  },
  {
    id: 11,
    storeId: 10,
    name: '아이더',
    condition: '15만원',
    imageUrl: Logo12,
    giftList: ['우산'],
    couponCode: '12',
  },
  {
    id: 12,
    storeId: 11,
    name: '스노우피크',
    condition: '15만원',
    imageUrl: Logo1,
    giftList: ['제습제'],
    couponCode: '13',
  },
  {
    id: 14,
    storeId: 12,
    name: '내셔널지오그래픽',
    condition: '10만원',
    imageUrl: Logo4,
    giftList: ['원형 캠핑테이블'],
    couponCode: '14',
  },
  {
    id: 4,
    storeId: 13,
    name: '노르디스크',
    condition: '10만원',
    imageUrl: Logo6,
    giftList: ['캠핑컵(법랑)'],
    couponCode: '15',
  },
  {
    id: 5,
    storeId: 14,
    name: '나이키스윔',
    condition: '10만원',
    imageUrl: Logo3,
    giftList: ['방수파우치'],
    couponCode: '16',
  },
  {
    id: 9,
    storeId: 15,
    name: '아레나',
    condition: '10만원',
    imageUrl: Logo11,
    giftList: ['수경 케이스'],
    couponCode: '17',
  },
  {
    id: 13,
    storeId: 16,
    name: '파타고니아',
    condition: '',
    imageUrl: Logo16,
    giftList: ['스티커'],
    couponCode: '18',
  },
  {
    id: 15,
    storeId: 17,
    name: '크록스',
    condition: '한켤레',
    imageUrl: Logo14,
    giftList: ['지비츠'],
    couponCode: '19',
  },
  {
    id: 10,
    storeId: 18,
    name: '키플링',
    condition: '20만원',
    imageUrl: Logo15,
    giftList: ['힙색겸용 파우치'],
    couponCode: '20',
  },
];

export const brandMap = new Map<number, Brand>();
brandInfoList.forEach((brand) => {
  brandMap.set(brand.id, brand);
});
