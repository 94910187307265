import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import * as THREE from 'three';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import queryString from 'query-string';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from '@emotion/styled';

const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    background-color: transparent;
    color: white;
    box-shadow: none;
    padding-bottom: 46px;
    margin-bottom: 0px !important;
  }
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: queryString.parse,
        objectToSearchString: queryString.stringify,
      }}
    >
      <App />
    </QueryParamProvider>
    <StyledToastContainer containerId={'coupon-save'} />
  </BrowserRouter>,
);
