import { HintWrapper } from '../information/hint/Hint';
import { IcInfo } from './IcInfo';
import styled from '@emotion/styled';

function CouponHint() {
  return (
    <Wrapper>
      <IcInfo color={'#FF9C63'} />
      <HintText>점원에게 저장된 이미지를 보여주세요</HintText>
    </Wrapper>
  );
}

export default CouponHint;

const HintText = styled.div`
  color: var(--g-200, #ececec);
`;

const Wrapper = styled.div`
  border-radius: 78px;
  border: 1px solid #1c1c1e;
  background: rgba(255, 255, 255, 0.08);

  display: flex;
  gap: 8px;
  padding: 12px 16px 12px 12px;
  height: min-content;
  width: max-content;
  margin: 0 auto;
  color: var(--common-100);
  font: var(--label-02-400);
  & > div {
    height: min-content;
    margin: auto;
  }
`;
