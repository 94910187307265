import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import './globals.css';
const TimesalePage = lazy(() => import('./page/TimesalePage'));
const OnlyVprPage = lazy(() => import('./page/OnlyVprPage'));
const OnlyTimesalePage = lazy(() => import('./page/OnlyTimesalePage'));
import IntroPage from './page/IntroPage';
import CouponDetailPage from './page/CouponDetailPage';
import '@hypercloud-kr/styling-kit';
import { lazy, useEffect, useMemo } from 'react';
import { SolutionFunnel } from '@hypercloud/webxr-components/src/api/funnel';
import { getDeviceId } from '@hypercloud/webxr-components/src/util/util';
import { Config } from './config';
const App = () => {
  // SolutionFunnel 초기화
  SolutionFunnel.init({
    deviceId: getDeviceId(),
    appKey: Config.instance.appKey,
    campaignId: Config.instance.campaignId,
  });

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<IntroPage />} />
        <Route path="/ar" element={<TimesalePage />} />
        <Route path="/coupon/:couponData" element={<CouponDetailPage />} />
        <Route path="/test/vpr" element={<OnlyVprPage />} />
        <Route path="/test/timesale" element={<OnlyTimesalePage />} />
        <Route path="/*" element={<div>404</div>} />
      </Routes>
    </div>
  );
};

export default App;
