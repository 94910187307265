import { useRef } from 'react';
import html2canvas from 'html2canvas';
import { isIOS } from 'react-device-detect';

export function useCaptureHtml() {
  const htmlRef = useRef<HTMLDivElement>(null);

  const capture = async () => {
    const element = htmlRef.current;
    if (!element)
      throw new Error(
        'html element 가 존재하지 않습니다. ref 를 등록해주세요.',
      );
    try {
      const canvas = await html2canvas(element, {
        // 이미지 cors
        allowTaint: true,
        useCORS: true,
      });

      const imgURL = canvas.toDataURL('image/png');

      await shareImage(imgURL).catch((e) => {
        const link = document.createElement('a');
        link.download = 'image.png';
        link.href = imgURL;
        link.click();
      });

      return true;
    } catch (error) {
      alert('이미지 다운로드를 지원하지 않는 기기입니다. 캡쳐해주세요.');

      return false;
    }
  };

  const shareImage = async (imgURL: string): Promise<void> => {
    if (!isIOS || !navigator.share) {
      throw new Error('지원하지 않는 브라우저입니다.');
    }
    const blob = dataURItoBlob(imgURL);

    const file: File = new File([blob], 'image.png', { type: 'image/png' });

    return await navigator
      .share({
        files: [file],
      })
      .then(() => {
        URL.revokeObjectURL(URL.createObjectURL(file));
      })
      .catch((e) => {
        throw e;
      });
  };

  return { htmlRef, capture };
}

function dataURItoBlob(dataURI) {
  const splitDataURI = dataURI.split(',');
  // Base64 데이터 부분을 가져
  const byteString = atob(splitDataURI[1]);
  // MIME 타입을 가져옴
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }
  return new Blob([arrayBuffer], { type: mimeString });
}
