import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import CouponHint from '../components/coupon/CouponHint';
import CouponDetail from '../components/coupon/CouponDetail';
import CouponDescription from '../components/coupon/CouponDescription';
import { CloseBar } from '../components/app-bar';
import SnappyModal from 'react-snappy-modal';
import ConfirmModal from '@hypercloud/webxr-components/src/components/Modal/ConfirmModal';
import { toast, ToastContainer } from 'react-toastify';
import {
  FunnelAttributionType,
  SolutionFunnel,
} from '@hypercloud/webxr-components/src/api/funnel';
import { CouponSaveToast } from '../components/coupon/CouponSaveToast';

function CouponDetailPage() {
  const params = useParams<{ couponData: string }>();
  const [isCouponSave, setIsCouponSave] = useState(false);

  const couponData = useMemo(() => {
    try {
      return JSON.parse(atob(params.couponData));
    } catch (e) {
      console.error(e);
    }
    return;
  }, [params]);
  if (!couponData) {
    return <div>잘못된 쿠폰입니다.</div>;
  }

  const handleClickClose = () => {
    if (isCouponSave) {
      window.history.back();
      return;
    }

    SnappyModal.show(
      <ConfirmModal
        title={'쿠폰을 저장하지 않고\n나가시겠습니까?'}
        description={'이벤트 기간 내 로고를 재인식하면\n다시 받을 수 있어요.'}
        confirmLabel={'계속하기'}
        onClickConfirm={() => {
          SnappyModal.close();
        }}
        cancelLabel={'나가기'}
        onClickCancel={() => {
          SnappyModal.close();
          window.history.back();
        }}
      />,
      {},
    ).then((result) => {
      console.log(result);
    });
  };

  const handleClickCouponSave = () => {
    toast(<CouponSaveToast />, {
      containerId: 'coupon-save',
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: false,
      autoClose: 2000,
      position: 'bottom-center',
    });
    SolutionFunnel.stack(FunnelAttributionType.BRAND_CATCHER_COUPON_SAVE, {
      // 2만번대가 목동점
      brandId: Number(couponData.brandId) + 20000,
    });
    setIsCouponSave(true);
  };

  return (
    <Wrapper>
      <CloseBar onClick={handleClickClose} />
      <CouponHint />
      <CouponDetail {...couponData} onClickCouponSave={handleClickCouponSave} />
      <CouponDescription />
      <ToastContainer />
    </Wrapper>
  );
}

export default CouponDetailPage;

const Wrapper = styled.div`
  background: var(--cool-neutral-5);
  padding-bottom: 88px;
`;

const Header = styled.header`
  padding: 14px 20px;
`;
